<template>
  <div v-if="hasResource('purchase_plan_audit_management')">
    <dialog-info ref="dialogInfoPop" @updateList="fetchList"/>
    <dialog-approval ref="dialogApprovalPop" @updateList="fetchList"/>
    <div class="aops-plan-approval">
      <div class="aop-searchForm search-form">
        <div class="aop-fix">
          <el-form ref="listFilter" :inline="true" :model="filter" >
           <el-form-item label="编号/名称"  prop="id_name">
              <el-input  v-model="filter.id_name" placeholder="输入编号/名称搜索" clearable @keyup.enter.native="addByEnterKey"/>
            </el-form-item>
            <el-form-item label="市场" prop="market">
              <el-select v-model="filter.market">
                <el-option v-for="item in marketOptions" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="方案" prop="programme">
              <el-select v-model="filter.programme">
                <el-option v-for="code in programmeType" :key="code.value" :label="code.label" :value="code.value"/>
              </el-select>
            </el-form-item>
             <el-form-item label="状态" prop="status">
              <el-select v-model="filter.status">
                <el-option v-for="code in statusOptions" :key="code.value" :label="code.label" :value="code.value"/>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="aop-textBtn">
            <span class="aop-clickButton" @click="handleSearch">查询</span>
            <span class="aop-clearButton aop-clickButton" @click="reset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        v-loading="isTableLoading"
        :data="data"
        style="width: 100%;"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell">
        <el-table-column
            v-for="col in tableColumn"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :min-width="col.minWidth"
            :show-overflow-tooltip="col.showOverflowTooltip"
            align="left"
            :fixed="col.fixed">
            <template slot-scope="{row}">
               <div v-if="col.prop === 'status'">
                  {{getStatus(row[col.prop])}}
                </div>
                <div v-else-if="col.prop === 'effective_at' || col.prop === 'expired_at' ">
                 {{setFormatDate(row[col.prop], col.prop === 'effective_at'? '立即启用':'永久')}}
                </div>
                  <!-- 现价 -->
                <div v-else-if="col.prop === 'price' || col.prop === 'original_price'">
                    {{row[col.prop] ? `${row.currency === 'USD' ? '$' : '¥'}${row[col.prop]}` : '--'}}
                </div>
                  <!-- 折扣 -->
                 <div v-else-if="col.prop === 'discount'">
                    {{`${row.discount}` !== '0' ? `-${row.discount}%` : '--'}}
                </div>
                <div v-else-if="col.prop === 'operation'" class="pa_icon-btn icon-btn">
                 <el-button
                  size="mini" type="text" class="preview-color" @click="fetchInfo(row)" >
                  <i class="iconfont">&#xe662;</i>查看
                </el-button>
                <el-button  v-if="hasResource('purchase_plan_audit_pass') && `${row.status}` === '10' && unapproved(row)"
                  size="mini" type="text" @click="handleItem(1,row)">
                  <i class="iconfont">&#xe65b;</i>审批
                </el-button>
                <el-button  v-if="hasResource('purchase_plan_audit_fail') && `${row.status}` === '10' && unapproved(row)"
                size="mini" type="text" @click="handleItem(2,row)">
                <i class="iconfont">&#xe664;</i>驳回
                </el-button>
                </div>
                <div v-else>{{row[col.prop] || '--'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div v-if="total != 0"  class="aop-pagination">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import info from './dialog/info';
import api from '../../../api/transcation';
import approvalDialog from './dialog/approval';
import { tableColumn } from '../_common/planData';
import { marketOptions, programmeType, statusOptions, filter } from './data.js';

export default {
    components: {
        DialogInfo: info,
        DialogApproval: approvalDialog
    },
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            marketOptions,
            programmeType,
            statusOptions,
            isTableLoading: false,
            total: 0,
            pageSize: 10,
            currentPage: 1,
            data: [],
            filter,
            dialog: false,
            btnLoading: false,
            current: {}
        };
    },
    computed: {
        /**
        * 表格列
        @return{*}
        */
        tableColumn() {
            return tableColumn('', 380);
        }
    },
    /** 创建时 */
    created() {
        this.fetchList();
    },
    /**
     * activated
    */
    activated() {
        if (this.$route.meta.keepAlive) {
            this.fetchList();
        }
    },
    methods: {
        /** 查询
         * @param {*} e 值
        */
        addByEnterKey(e) {
            if (e.keyCode == 13) {
                this.fetchList();
            }
        },
        /** pageSize 更改
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.fetchList();
        },
        /** 当前页数查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.fetchList();
        },
        /** 重置 */
        reset() {
            this.filter = { id_name: '',
                market: '',
                status: 0,
                programme: '积分方案' };
        },
        /** 查询 */
        handleSearch() {
            this.currentPage = 1;
            this.fetchList();
        },
        /**
        * 列表数据
        @returns{*}
        */
        fetchList() {
            this.isTableLoading = true;
            const data = {
                limit: this.pageSize,
                page: this.currentPage,
                filter: { ...this.filter, programme: '', status: !this.filter.status ? '' : this.filter.status }
            };
            try {
                api.planAuditList(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.data = res.data.data.items;
                        this.total = res.data.data.total;
                        this.currentPage = res.data.data.page;
                    }
                    this.isTableLoading = false;
                });
            } catch (e) {
                return e;
            }
        },
        /** 查询
        * @param {*} val // 10:审核中 / 11:已撤回 / 12:已驳回 / 13:待启用 / 1:已启用 / 0:已停用
         @return {*}
        */
        getStatus(val) {
            switch (val) {
                case 0:
                    return '已停用';
                case 1:
                    return '已启用';
                case 10:
                    return '待审批';
                case 11:
                    return '已撤回';
                case 12:
                    return '已驳回';
                case 13:
                    return '待启用';
                default:
                    break;
            }
        },
        /** 查询
        * @param {*} type 审批/ 驳回 1/2
        * @param {*} row 当前处理的数据
        */
        handleItem(type, row) {
            this.$nextTick(() => {
                this.$refs.dialogApprovalPop.show(row, type);
            });
        },
        /** 查询
        * @param {*} row 当前数据
        */
        fetchInfo(row) {
            this.$nextTick(() => {
                this.$refs.dialogInfoPop.show(row);
            });
        },
        /**
         时间
        * @param {*} val 当前数据
        * @param {*} txt 当前数据
         @return {*}
         */
        setFormatDate(val, txt) {
            return formatDate(val * 1000) || txt;
        },
        /**
        【（当前时间-1天）> 结束时间】（即结束时间的前一天之后的时间），改条方案将不再显示“审批”“驳回”操作按钮
         有效时间> 当前时间+1天时，不审批操作按钮
         @param {*} row 当前数据
         @returns {*}
        */
        unapproved(row) {
            if (`${row.expired_at}` === '0' || (row.expired_at && row.expired_at * 1000 > Date.now() + 24 * 60 * 60 * 1000)) {
                return true;
            }
            return false;
        }
    }
};
</script>

<style lang="less">
    @import "./list.less";
</style>
