<template>
  <div class="aops-info">
    <dialog-approval ref="dialogApprovalPop" @updateList="updateList"/>
    <el-dialog
      top="5%"
      :close-on-click-modal="false"
      title="查看积分方案"
      :visible.sync="dialogVisible"
      width="444px"
      :modal-append-to-body="false"
      class="pop-dialog aops-code-dialog"
    >
      <div v-for="item in dialogInfo" :key="item.label" class="aop-info-warp">
       <span class="aop-info-label">{{item.label}}</span>
       <span class="aop-info-value"  :style="`color:${item.color}`">
        <span v-if="item.label ==='货币'">{{current.market=== 'CN'?'人民币':'美元'}}</span>
        <span v-else-if="item.label ==='类型'">{{`${current.type}` === '1'?'固定充值':'其它充值'}}</span>
        <span v-else-if="item.key === 'effective_at' || item.key === 'expired_at' ">
            {{setFormatDate(current[item.key], item.key === 'effective_at'? '立即启用' : '永久')}}
        </span>
        <span v-else-if="item.key === 'original_price' || item.key === 'price' " :style="`text-decoration:${item.textDecoration}`">
          {{current.market=== 'CN'? '¥ ' : '$ '}} {{current[item.key]}}
        </span>
        <span v-else-if="item.key==='discount'">-{{current[item.key]}}%</span>
        <span v-else>{{current[item.key]}}</span>
       </span>
      </div>
     <div class="aop-info-status">
      <span class="aop-info-status">{{getStatus(current.status)}}</span>
       <span v-if="`${current.status}`=== '12' || `${current.status}` === '13'" class="aop-value-info" >
        {{`${current.status}`=== '13' ? '审批人': '驳回人'}} : {{current.username}}
        <br/>
       {{setFormatDate(current.updated_at)}}
       </span>
     </div>
      <span v-if="`${current.status}` === '10'"
        slot="footer" class="dialog-footer">
        <el-button
            v-if="hasResource('purchase_plan_audit_fail') && unapproved()"
            type="primary"
            class="confirm-button"
            size="medium"
            :loading="btnLoading"
            @click="sure">审批</el-button>
        <el-button
           v-if="hasResource('purchase_plan_audit_fail') && unapproved()"
           class="cancel-button"
           :loading="loading"
           @click="reject">驳回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import { dialogInfo } from '../data.js';
import approvalDialog from './approval';

export default {
    components: {
        DialogApproval: approvalDialog
    },
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            dialogVisible: false,
            btnLoading: false,
            loading: false,
            dialogInfo,
            current: {}
        };
    },
    methods: {
        /** 确认驳回
        "status":1,    //1 审核通过审核 0 驳回 */
        reject() {
            this.hide();
            this.$nextTick(() => {
                this.$refs.dialogApprovalPop.show(this.current, 0);
            });
        },
        /** 审批 */
        sure() {
            this.hide();
            this.$nextTick(() => {
                this.$refs.dialogApprovalPop.show(this.current, 1);
            });
        },
        /** 显示弹窗
         * @param {*} data 值
        */
        show(data) {
            this.current = data;
            this.dialogVisible = true;
        },
        /** 关闭弹窗  */
        hide() {
            this.dialogVisible = false;
        },
        /** 数据更新发送消息 */
        updateList() {
            this.$emit('updateList');
        },
        /** 查询
        * @param {*} val // 10:审核中 / 11:已撤回 / 12:已驳回 / 13:待启用 / 1:已启用 / 0:已停用
         @return {*}
        */
        getStatus(val) {
            switch (`${val}`) {
                case '0':
                    return '已停用';
                case '1':
                    return '已启用';
                case '10':
                    return '待审批';
                case '11':
                    return '已撤回';
                case '12':
                    return '已驳回';
                case '13':
                    return '已审批';
                default:
                    break;
            }
        },
        /**
        时间
          * @param {*} val 当前数据
          * @param {*} txt 当前数据
         @return {*}
         */
        setFormatDate(val, txt) {
            return formatDate(val * 1000) || txt;
        },
        /**
         时间
        * @param {*} val 当前数据
        * @param {*} txt 当前数据
         @return {*}
         */
        getFormatDate(val, txt) {
            return val || formatDate(val * 1000) || txt;
        },
        /**
        【（当前时间-1天）> 结束时间】（即结束时间的前一天之后的时间），改条方案将不再显示“审批”“驳回”操作按钮
         @returns {*}
        */
        unapproved() {
            const item = this.current;
            if (`${item.expired_at}` === '0' || (item.expired_at && item.expired_at * 1000 > Date.now() + 24 * 60 * 60 * 1000)) {
                return true;
            }
            return false;
        }
    }
};
</script>

<style lang="less">
@import "./dialog.less";
</style>
