export const dialogInfo = [
    { label: '市场', key: 'market' },
    { label: '方案名称', key: 'name', color: '#C0C4CC' },
    { label: '货币', key: 'currency', color: '#C0C4CC' },
    { label: '类型', key: 'type', color: '#C0C4CC' },
    { label: '积分', key: 'amount' },
    { label: '原价', key: 'original_price', textDecoration: 'line-through' },
    { label: '现价', key: 'price' },
    { label: '省下折扣', key: 'discount' },
    { label: '启用时间', key: 'effective_at' },
    { label: '有效期', key: 'expired_at' }
];

// programme: '积分方案',

export const filter = {
    id_name: '',
    market: '',
    status: 0,
    programme: '积分方案'
};

export const programmeType = [
    {
        value: '积分方案',
        label: '积分方案'
    }
];

export const marketOptions = [
    {
        value: '',
        label: '全部'
    },
    {
        value: 'US',
        label: 'US'
    },
    {
        value: 'CN',
        label: 'CN'
    }
];
//  "status":"",  //0:全部  10:待审批  13:已审批  12:已驳回
export const statusOptions = [
    {
        value: 0,
        label: '全部'
    },
    {
        value: 10,
        label: '待审批'
    },
    {
        value: 12,
        label: '已驳回'
    },
    {
        value: 13,
        label: '已审批'
    }
];
