<template>
  <div class="aops-dialog">
    <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="458px"
        class="add-dialog dialogTip">
        <div class="dialog-content">
            <div class="dialog-title aop-dialog-title">
              {{getTitle()}}
              <div v-if="type === 1">{{getSubTitle()}}</div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
            <el-button class="confirm-button" :loading="btnLoading" @click="submit()">确认</el-button>
            <el-button class="cancel-button" @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
  </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import api from '../../../../api/transcation';
import { dialogInfo } from '../data.js';

export default {
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            dialogVisible: false,
            btnLoading: false,
            loading: false,
            dialogInfo,
            type: 0,
            current: {}
        };
    },
    methods: {
        /** 确认提交
         "status":1,   //13 审核通过审核 12 驳回
         */
        submit() {
            if (this.type === 1) {
                this.sure(13);
            } else {
                this.sure(12);
            }
        },
        /** 确认审批
         * @param {*} status 值
         */
        sure(status) {
            this.btnLoading = true;
            const data = {
                id: this.current.id,
                status
            };
            api.changeStatus(data).then((resp) => {
                if (resp.data.code === 10200) {
                    this.$$success('操作成功');
                    this.updateList();
                    this.hide();
                } else if (resp.data.code === 10201) {
                    this.$$error('同一市场中相同积分仅可启用一个方案，启用新方案将自动停用旧方案');
                    this.updateList();
                    this.hide();
                } else {
                    this.$$error(resp.data.message);
                }
                this.btnLoading = false;
            });
        },
        /** 弹窗标题
         @return {*}
        */
        getTitle() {
            return this.type === 1 ? `确认 【${this.current.name}】 审批通过 ?` : `确认驳回 【${this.current.name}】?`;
        },
        /** 弹窗二级标题
         当前时间大于启用时间，审批弹窗应显示通过1小时后将启用此方案
         @return {*}
        */
        getSubTitle() {
            if (!this.current.effective_at || (this.current.effective_at * 1000 < new Date().getTime())) {
                return '通过1小时后将启用此方案';
            }
            const time = formatDate(this.current.effective_at * 1000);
            return `${time} 将启用此方案`;
        },
        /** 显示弹窗
         * @param {*} data 值
         * @param {*} type 值 1, 2

        */
        show(data, type) {
            this.current = data;
            this.type = type;
            this.dialogVisible = true;
            this.btnLoading = false;
        },
        /** 关闭弹窗  */
        hide() {
            this.dialogVisible = false;
        },
        /** 数据更新发送消息 */
        updateList() {
            this.$emit('updateList');
        },
        /** 查询
        * @param {*} val 值待审批 / 已审批/ 已驳回
         @return {*}
        */
        getStatus(val) {
            switch (val) {
                case 1:
                    return '待审批';
                case 2:
                    return '已审批';
                case 3:
                    return '已驳回';
                default:
                    break;
            }
        }
    }
};
</script>

<style lang="less">
@import "./dialog.less";
</style>
